
.app{
  display: flex;
  flex-direction:column;
  height : 100%;
}
.content{
  display: flex;
  flex:1;
  margin-bottom: 10px;
}
.dashboard{
 display: flex;
 flex: 1;
}
.iframe-container {
display: flex;
justify-content: center;
align-items: center;
height: 100vh; /* Full viewport height */
width: 100vw;  /* Full viewport width */
margin: 0;
padding: 0;
}
iframe{
border: none;
height: 100%;
width: 100%;
}
.title{
  display: flex;
  flex:1;
  box-align: center;
}
.flex-container{
  display:flex;
  gap:10px;
  justify-content: flex-end;
}
.flex-item{
  flex:1;
  padding: 20px;
  border: 1px solid #ccc;
  width: realtive;
}
.dual-list {
  display: flex;
  gap: 20px; /* Adjust as needed */
}

.column {
  width: 250px;  /* Adjust as needed */
  border: 1px solid #ccc;
  padding: 10px;
}

.column ul {
  list-style: none;
  padding: 0;
}

.column li {
  cursor: pointer;
  padding: 5px;
}

.column li:hover {
  background-color: #f5f5f5;
}

.arrow-buttons {
  display: flex;
  flex-direction: column; /* Stack buttons vertically */
  justify-content: center; /* Center vertically */
  align-items: center;   /* Center horizontally */
  gap: 10px;  /* Add some space between buttons */
}

.arrow-buttons button {
  padding: 10px 15px;
  border: none;
  background-color: black; /* Example color */
  color: white;
  cursor: pointer;
  border-radius: 5px;
}

.arrow-buttons button:disabled {
  background-color: #ccc; /* Grayed out when disabled */
  cursor: default;
}

.settings-box{
  border: 2px solid #707072;
  border-radius: 8px;
  padding: 8px;
}

.floating-header-container{
  display: flex;
  justify-content: center;
  align-items: center;
}


#footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 50px;
  background-color: #f5f5f5;
  text-align: center;
  padding-top: 10px;
  vertical-align: middle;
}

